<template>
  <div
    id="restrictions"
    class="init"
  >
    <AccountRestricted
      v-if="typePopup === 'account-restricted'"
      :callback="callback"
      @on-close="typePopup = ''"
    />
  </div>
</template>

<script>

import AccountRestricted from './components/AccountRestricted.vue';

export default {
  name       : 'Restrictions',
  components : {
    AccountRestricted
  },

  data() {
    return {
      typePopup : '',
      callback  : null
    };
  },

  created() {
    document.addEventListener('restrictions:open', (event) => this.openPopup(event));
  },

  methods: {
    openPopup(event) {
      this.typePopup = event.detail && event.detail.type ? event.detail.type : '';
      this.callback = event.detail && event.detail.callback ? event.detail.callback : null;
    }
  }
};
</script>
