import AbstractApiModule from '../../AbstractApiModule';
import { getAuthConfig } from '../../../utils/getAuthConfig';
import { globalValidateMethod } from '../../GlobalValidateMethods';

const urlFactory = {
  accountInfo                : '/v2/internal/account/info/',
  getConsumerInfo            : '/v2/internal/consumer/info/',
  setPersonalProfile         : '/v2/internal/account/info/',
  personalLensesInfo         : '/v2/internal/personal/profile/lenses_info/',
  setPersonalEmail           : '/v2/internal/personal/profile/email/',
  getPersonalDeliveryAddress : '/v2/internal/personal/delivery_address/',
  accountUtils               : '/v2/internal/account/utils/',
  changePassword             : '/v2/internal/personal/change_password/',
  applyReferral              : '/v2/internal/referral/apply/',
  getReferralInfo            : '/v2/internal/referral/info/',
  readNotification           : (id) => `/v2/internal/account/notifications/${id}/read/`,
  readNotificationsInvites   : '/v2/internal/specialist/invites/read_notifications/'
};

export default class UserModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getAccountInfo(request) {
    if ('memoFetch' in window && !request) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.accountInfo}`, !request).then(({ data }) => {
          resolve(data);
        }).catch((response) => {
          globalValidateMethod({ response: { data: response } });
          reject(response);
        });
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.accountInfo).then(({ data }) => {
        if (data) {
          resolve(data);
        }
      }).catch(reject);
    });
  }

  getConsumerInfo(request) {
    if ('memoFetch' in window && !request) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.getConsumerInfo}`, !request).then(({ data }) => {
          resolve(data);
        }).catch((response) => {
          globalValidateMethod({ response: { data: response } });
          reject(response);
        });
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.getConsumerInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setPersonalProfile(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setPersonalProfile, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getPersonalLensesInfo() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.personalLensesInfo).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setPersonalLensesInfo(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.personalLensesInfo, params).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setPersonalEmail(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setPersonalEmail, params, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getPersonalDeliveryAddress() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getPersonalDeliveryAddress).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  // Изменение пароля
  changePassword(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.changePassword, params, getAuthConfig()).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getAccountUtils() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.accountUtils).then(({ data }) => {
        if (data) {
          resolve(data);
        }
      }).catch(reject);
    });
  }

  // Реферальная программа
  applyReferral(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.applyReferral, params, getAuthConfig()).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Реферальная программа
  getReferralInfo(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getReferralInfo, params, getAuthConfig()).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Прочтение уведомления
  readNotification(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.readNotification(id), {}, getAuthConfig()).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  // Прочтение всех уведомлений реферальной программы
  readNotificationsInvites() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.readNotificationsInvites, {}, getAuthConfig()).then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
