import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  copyEntries         : (id) => `/v2/internal/call_center/entries/${id}/copy/`,
  entriesCallCenter   : (id) => `/v2/internal/call_center/entries/${id}/`,
  entriesCallCenterV1 : (id) => `/v1/call-center/entries/${id}/`
};

export default class CallCenterModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  copyEntries(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.copyEntries(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getDetailEntries(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.entriesCallCenter(id)).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  updateEntries(id, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.entriesCallCenterV1(id), params).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }
}
