var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "svg-icon", class: _vm.classes }, [
    _c("svg", [
      _c("use", {
        attrs: {
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          "xlink:href": _vm.iconHashed
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }