var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MessagePopupUI",
    {
      attrs: {
        "max-width": 614,
        title: "Ваш аккаунт заблокирован",
        text: _vm.text,
        error: true
      },
      on: { "on-close": _vm.onClose }
    },
    [
      _c(
        "button",
        {
          staticClass: "btn-ui btn-ui--full",
          attrs: { type: "button" },
          on: { click: _vm.onClose }
        },
        [_vm._v("\n    Понятно\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }