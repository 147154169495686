import AbstractApiModule from '../../AbstractApiModule';
import { getConvertDataProduct } from '../../../utils/product';

const urlFactory = {
  getDetailProduct      : (id) => `/v2/internal/catalog/products/detail/id/${id}/`,
  getAdditionalProducts : (id, type) => `/v2/internal/catalog/products/detail/id/${id}/lists/related_products_${type}/items/`,
  getParamsProduct      : (id) => `/v2/internal/catalog/products/detail/id/${id}/params/`,
  getOffersLenses       : (type) => `/v2/internal/catalog/products/offers/${type}/`
};

export default class ProductModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getDetailProduct(id) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDetailProduct(id))
        .then(({ data }) => {
          const product = data && data.data ? data && data.data : null;
          let result;

          if ((product.sale.eshop && product.sale.eshop.packs && product.sale.eshop.packs.length)
            || (product.sale.mono && product.sale.mono.packs && product.sale.mono.packs.length)
          ) {
            result = getConvertDataProduct(product);
          }

          resolve(result);
        })
        .catch(reject);
    });
  }

  getAdditionalProducts(idProduct, type, params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getAdditionalProducts(idProduct, type), { params })
        .then(({ data }) => {
          const listProducts = [];

          if (data.data && data.data && data.data.length) {
            data.data.forEach((el) => {
              const product = getConvertDataProduct(el);

              if (Array.isArray(product)) {
                // eslint-disable-next-line max-nested-callbacks
                product.forEach((item) => {
                  listProducts.push(item);
                });
              } else {
                listProducts.push(product);
              }
            });
          }

          resolve(listProducts);
        })
        .catch(reject);
    });
  }

  getParamsProduct(idProduct, params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getParamsProduct(idProduct), { params })
        .then(({ data }) => {
          let result;

          if (data && data.data && data.data.properties) {
            result = data.data.properties;
          }

          resolve(result);
        })
        .catch(reject);
    });
  }

  getOffersLenses(type = 'mono', productId) {
    return new Promise((resolve, reject) => {
      const params = productId ? { product_id: productId } : {};

      this.get(urlFactory.getOffersLenses(type), { params })
        .then(({ data }) => {
          resolve(data.data.offers);
        })
        .catch(reject);
    });
  }
}
