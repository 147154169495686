<template>
  <PopupUI
    :max-width="maxWidth"
    :prevent-close-event="preventCloseEvent"
    class="message-popup-ui"
    :class="{'message-popup-ui--full': fullWidth}"
    @on-close="$emit('on-close')"
  >
    <template slot="header">
      <div class="message-popup-ui__header txt-center">
        <div class="message-popup-ui__image">
          <Icon
            :icon="currentIcon"
            class="message-popup-ui__icon txt-cyan-secondary"
          />
        </div>

        <template v-if="$slots.header">
          <slot name="header" />
        </template>
        <template v-else>
          <h3
            v-if="title"
            class="message-popup-ui__title subtitle-h3 txt-blue-common l-height-s"
            v-html="title"
          />

          <p
            v-if="text"
            class="message-popup-ui__descr txt-m txt-black no-margin l-height-s"
            v-html="text"
          />
        </template>
      </div>
    </template>

    <slot />
  </PopupUI>
</template>

<script>
import PopupUI from './common/PopupUI.vue';
import Icon from './common/Icon.vue';
import '../../../../svg-icons/r-success.svg';
import '../../../../svg-icons/r-error.svg';
import '../../../../svg-icons/r-call-center.svg';

export default {
  name       : 'MessagePopupUI',
  components : {
    Icon,
    PopupUI
  },

  props: {
    error: {
      type    : Boolean,
      default : false
    },
    title: {
      type    : String,
      default : ''
    },
    text: {
      type    : String,
      default : ''
    },
    fullWidth: {
      type    : Boolean,
      default : false
    },
    preventCloseEvent: {
      type    : Boolean,
      default : false
    },
    icon: {
      type    : String,
      default : ''
    },
    maxWidth: {
      type    : Number,
      default : 540
    }
  },

  computed: {
    currentIcon() {
      if (this.icon) {
        return this.icon;
      }

      return this.error ? 'r-error' : 'r-success';
    }
  }
};
</script>

<style scoped lang="scss">
.message-popup-ui {
  &__header {
    padding-top: 12px;

    @media (min-width: 1024px) {
      padding-top: 16px;
    }
  }
  &__icon {
    max-width: 100px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
  &__title {
    margin: 24px 0 0;
    font-weight: 600;

    @media (min-width: 1024px) {
      margin-top: 32px;
    }
  }
  &__descr {
    max-width: 440px;
    margin: 16px auto 0;
  }
  &--full & {
    &__descr {
      max-width: none;
    }
  }
}
</style>
