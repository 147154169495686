<template>
  <span
    class="svg-icon"
    :class="classes"
  >
    <svg>
      <use
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :xlink:href="iconHashed"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'Icon',

  props: {
    icon: {
      type     : String,
      required : true
    }
  },

  computed: {
    classes() {
      return [`svg-icon--${this.icon}`];
    },

    iconHashed() {
      return `#${this.icon}`;
    }
  }
};
</script>
