import ApiClient from './ApiClient';
import { globalValidateMethod } from './GlobalValidateMethods';

export default class AbstractApiModule {
  constructor(options = {}) {
    this.baseURL = options.baseURL || '/';
    this.apiURL = options.apiURL || '';
    this.client = options.apiClient || new ApiClient({
      baseURL: this.baseURL,
      ...options.clientOptions || {}
    });
    this.clientInstanse = this.client.axios;
  }

  setToken(token) {
    this.clientInstanse.defaults.headers.common['Authorization-Token'] = token;
  }

  get(url = '', data, config = {}) {
    return new Promise((resolve, reject) => {
      this.clientInstanse.get(this.apiURL + url, data, config)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          globalValidateMethod(response);
          reject(response);
        });
    });
  }

  post(url = '', data, config = {}) {
    return new Promise((resolve, reject) => {
      this.clientInstanse.post(this.apiURL + url, data, config)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          globalValidateMethod(response);
          reject(response);
        });
    });
  }

  put(url = '', data, config = {}) {
    return new Promise((resolve, reject) => {
      this.clientInstanse.put(this.apiURL + url, data, config)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          globalValidateMethod(response);
          reject(response);
        });
    });
  }

  delete(url = '', data, config = {}) {
    return new Promise((resolve, reject) => {
      this.clientInstanse.delete(this.apiURL + url, { data, ...config })
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          globalValidateMethod(response);
          reject(response);
        });
    });
  }
}
