import AbstractApiModule from '../../AbstractApiModule';
import { globalValidateMethod } from '../../GlobalValidateMethods';

const urlFactory = {
  getDetailSubscription  : '/v2/internal/basket/eshop/wizard/',
  getActiveSubscriptions : '/v1/eshop/subscriptions/active/',
  getListSubscriptions   : '/v1/eshop/subscriptions/types/',

  cancelSubscription    : '/v2/internal/personal/subscription/active/cancel/',
  getHistoryOrders      : '/v2/internal/personal/subscription/active/orders/history/',
  getCurrentOrder       : '/v2/internal/personal/subscription/active/orders/current/',
  setDataRecipient      : '/v2/internal/personal/subscription/active/orders/current/recipient/set/',
  getOffersSubscription : '/v2/internal/personal/subscription/active/orders/current/items/lenses/offers/',

  setAddressDelivery:
    (deliveryAddressType) => `/v2/internal/personal/subscription/active/orders/current/delivery_address/${deliveryAddressType}/set/`
};

export default class SubscriptionsModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getDetailSubscription(params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getDetailSubscription, params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getActiveSubscriptions(request) {
    if ('memoFetch' in window && !request) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.getActiveSubscriptions}`, !request).then((data) => {
          resolve(data);
        }).catch((response) => {
          globalValidateMethod({ response: { data: response } });
          reject(response);
        });
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.getActiveSubscriptions).then((data) => {
        if (data && data.data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getListSubscriptions() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListSubscriptions).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  cancelSubscription() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.cancelSubscription).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getHistoryOrders() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getHistoryOrders).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getCurrentOrder() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getCurrentOrder).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  setDataRecipient(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setDataRecipient, params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  setAddressDelivery(deliveryAddressType, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setAddressDelivery(deliveryAddressType), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getOffersSubscription(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getOffersSubscription, { params })
        .then(({ data }) => {
          if (data && data.data) {
            resolve({ offers: data.data.offers });
          } else {
            reject(data);
          }
        })
        .catch(reject);
    });
  }
}
