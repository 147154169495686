<template>
  <MessagePopupUI
    :max-width="614"
    title="Ваш аккаунт заблокирован"
    :text="text"
    :error="true"
    @on-close="onClose"
  >
    <button
      type="button"
      class="btn-ui btn-ui--full"
      @click="onClose"
    >
      Понятно
    </button>
  </MessagePopupUI>
</template>

<script>
import MessagePopupUI from '../../../components/vue/MessagePopupUI.vue';

export default {
  name       : 'AccountRestricted',
  components : {
    MessagePopupUI
  },
  props: {
    callback: {
      type    : Function,
      default : null
    }
  },
  data() {
    return {
      text: `Для разблокировки, пожалуйста, позвоните<br class="d-none d-sm-inline">
        в&nbsp;наш <nobr>колл-центр</nobr> по&nbsp;телефону
        <a href="tel:+74954814356" class="txt-black no-decoration"><nobr>+7(495) 481-43-56</nobr></a>`
    };
  },
  methods: {
    onClose() {
      if (this.callback && typeof this.callback === 'function') {
        this.callback();
      }

      this.$emit('on-close');
    }
  }
};
</script>
