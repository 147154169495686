var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "init", attrs: { id: "restrictions" } },
    [
      _vm.typePopup === "account-restricted"
        ? _c("AccountRestricted", {
            attrs: { callback: _vm.callback },
            on: {
              "on-close": function($event) {
                _vm.typePopup = ""
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }