import { parseConfig } from '../../utils/json-config';

const accountRestricted = () => {
  const restrictions = document.querySelector('#restrictions');

  if (restrictions) {
    // Блокировка аккаунта
    document.dispatchEvent(new CustomEvent('restrictions:open', {
      detail: {
        type: 'account-restricted'
      }
    }));
  }
};

const setUnauthenticated = () => {
  const authHolder = document.querySelector('.auth-holder-ui');

  // Если на странице нет попапа авторизации - добавляем его
  if (!document.querySelector('#auth') && !authHolder) {
    const el = document.querySelector('#page_root');
    const div = document.createElement('div');
    div.id = 'auth';
    el.append(div);
  }

  const auth = document.querySelector('#auth');
  const openAuth = () => {
    document.dispatchEvent(new CustomEvent('auth:show', {
      detail: {
        subtitle : 'Ваша сессия истекла. Пожалуйста, авторизуйтесь снова, чтобы продолжить работу.',
        callback : () => {
          window.location.reload();
        }
      }
    }));
  };

  // Загружаем компонент авторизации
  if (auth) {
    import(
      /* webpackChunkName: "auth" */
      '../components/vue/Auth/index'
    ).then((partial) => {
      partial.default(auth);
      openAuth();
    });
  } else if (authHolder) {
    openAuth();
  }
};

export const globalValidateMethod = (data) => {
  if (data && data.response && data.response.data && data.response.data.errors && data.response.data.errors.length) {
    const config = parseConfig();
    const codes = data.response.data.errors.reduce((acc, x) => [...acc, x.code], []);

    if (codes.includes(9001)) {
      accountRestricted();
    }

    if (codes.includes(8001) && config.isUserAuthorized) {
      setUnauthenticated();
    }
  }
};
