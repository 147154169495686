var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PopupUI",
    {
      staticClass: "message-popup-ui",
      class: { "message-popup-ui--full": _vm.fullWidth },
      attrs: {
        "max-width": _vm.maxWidth,
        "prevent-close-event": _vm.preventCloseEvent
      },
      on: {
        "on-close": function($event) {
          return _vm.$emit("on-close")
        }
      }
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "message-popup-ui__header txt-center" },
          [
            _c(
              "div",
              { staticClass: "message-popup-ui__image" },
              [
                _c("Icon", {
                  staticClass: "message-popup-ui__icon txt-cyan-secondary",
                  attrs: { icon: _vm.currentIcon }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.$slots.header
              ? [_vm._t("header")]
              : [
                  _vm.title
                    ? _c("h3", {
                        staticClass:
                          "message-popup-ui__title subtitle-h3 txt-blue-common l-height-s",
                        domProps: { innerHTML: _vm._s(_vm.title) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.text
                    ? _c("p", {
                        staticClass:
                          "message-popup-ui__descr txt-m txt-black no-margin l-height-s",
                        domProps: { innerHTML: _vm._s(_vm.text) }
                      })
                    : _vm._e()
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }