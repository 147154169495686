import Vue from 'vue';
import App from './App.vue';
import { mainApi } from '../../api';
import { parseConfig } from '../../../utils/json-config';

const restrictions = document.querySelector('#restrictions');
if (restrictions) {
  new Vue({
    el     : '#restrictions',
    render : (h) => h(App)
  });
}

const isValidateUser = (data) => {
  // Полная блокировка аккаунта
  if (data
    && data.restrictions
    && data.restrictions.length
    && data.restrictions.find((el) => el.type === 'full')
  ) {
    mainApi.modules.authorization.userLogout();

    document.dispatchEvent(new CustomEvent('restrictions:open', {
      detail: {
        type     : 'account-restricted',
        callback : async () => {
          // перезагружаем после закрытия попапа
          window.location.reload();
        }
      }
    }));
  }
};

window.addEventListener('load', () => {
  const config = parseConfig();

  if (config.isUserAuthorized) {
    mainApi.modules.user.getAccountInfo().then((data) => {
      isValidateUser(data);
    });
  }
});
