import AbstractApiModule from '../../AbstractApiModule';
import { getConvertDataProduct } from '../../../utils/product';
import { getConvertDataBasket } from '../../../utils/basket';
import { globalValidateMethod } from '../../GlobalValidateMethods';
import { getAuthConfig } from '../../../utils/getAuthConfig';

const urlFactory = {
  getBasket      : '/v2/internal/basket/',
  validateBasket : '/v2/internal/basket/validate/',
  addLenses      : (type) => `/v1/${type}/basket/items/add/lenses/`,
  addEshopLenses : '/v2/internal/basket/eshop/items/',

  addAdditional : (type) => `/v1/${type}/basket/items/add/additional/`,
  removeProduct : (type) => `/v1/${type}/basket/items/remove/`,

  getListAdditional : (type) => `/v1/${type}/products/additional/`, // Все доп.товары (капли, растворы)
  getListLenses     : (type) => `/v1/${type}/products/lenses/?with_offers=true`, // Все линзы

  getAdditionalProductsInBasket: '/v2/internal/catalog/products/basket/recommended/',

  setDiscountPointsClub   : '/v2/internal/basket/points_discount/apply/',
  unsetDiscountPointsClub : '/v2/internal/basket/points_discount/remove/'
};

const configFormData = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default class CatalogModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getBasket(requestBasket, params = {}) {
    if ('memoFetch' in window && !requestBasket) {
      return new Promise((resolve, reject) => {
        window.memoFetch(`/api${urlFactory.getBasket}`, !requestBasket).then(({ data }) => {
          const result = getConvertDataBasket(data);
          resolve(result);
        }).catch((response) => {
          globalValidateMethod({ response: { data: response } });
          reject(response);
        });
      });
    }

    return new Promise((resolve, reject) => {
      this.get(urlFactory.getBasket, { params }).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataBasket(data.data);
          resolve(result);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  validateBasket() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.validateBasket).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  addEshopLenses(type, params = {}) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.addEshopLenses, params).then(({ data }) => {
        if (data && data.data) {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  addLenses(type, params = {}) {
    const form = new FormData();
    Object.keys(params).forEach((key) => {
      form.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
      this.post(urlFactory.addLenses(type), form, configFormData).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataBasket(data.data);
          resolve({ ...data, data: result });
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  addAdditional(type, params = {}) {
    const form = new FormData();
    Object.keys(params).forEach((key) => {
      form.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
      this.post(urlFactory.addAdditional(type), form).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataBasket(data.data);
          resolve({ ...data, data: result });
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  removeProduct(type, params = {}) {
    const form = new FormData();
    Object.keys(params).forEach((key) => {
      form.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
      this.post(urlFactory.removeProduct(type), form).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataBasket(data.data);
          resolve(result);
        } else {
          reject(data);
        }
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  getListLenses(type) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListLenses(type)).then(({ data }) => {
        resolve(data.data);
      })
        .catch(reject);
    });
  }

  getListAdditional(type) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListAdditional(type)).then(({ data }) => {
        resolve(data.data);
      })
        .catch(reject);
    });
  }

  getAdditionalProductsInBasket(params = {}) {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getAdditionalProductsInBasket, { params })
        .then(({ data }) => {
          const listProducts = [];

          if (data.data && data.data.products && data.data.products.length) {
            data.data.products.forEach((el) => {
              const product = getConvertDataProduct(el);

              if (Array.isArray(product)) {
                // eslint-disable-next-line max-nested-callbacks
                product.forEach((item) => {
                  listProducts.push(item);
                });
              } else {
                listProducts.push(product);
              }
            });
          }

          resolve(listProducts);
        })
        .catch(reject);
    });
  }

  setDiscountPointsClub() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setDiscountPointsClub, {}, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }

  unsetDiscountPointsClub() {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.unsetDiscountPointsClub, {}, getAuthConfig()).then(({ data }) => {
        resolve(data);
      }).catch(({ response : { data } }) => {
        reject(data);
      });
    });
  }
}
