var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "popupUI",
      staticClass: "popup-ui",
      class: {
        "inner-scroll": _vm.innerScroll,
        "inner-scroll-mobile": _vm.innerScrollMobile,
        "no-inner-indent": _vm.noInnerIndent,
        "no-animation": _vm.noAnimation
      }
    },
    [
      _c("div", {
        staticClass: "popup-ui__backdrop",
        class: {
          "hide-pointer":
            _vm.hidePointerCursorOnBackdrop || _vm.preventCloseEvent
        },
        on: { click: _vm.onClose }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "popup-ui__inner",
          class: { small: _vm.small, "bg-dark": _vm.$slots.header },
          style: _vm.externalCss
        },
        [
          !_vm.hideCloseButton && !_vm.preventCloseEvent
            ? _c(
                "div",
                { staticClass: "popup-ui__close", on: { click: _vm.onClose } },
                [
                  _c("Icon", {
                    staticClass: "popup-ui__close-icon",
                    attrs: { icon: "r-close-window-rounded" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { ref: "basePopupInner", staticClass: "popup-ui__content" },
            [
              _vm.innerScroll ||
              (_vm.innerScrollMobile && _vm.typeDevice === "mobile")
                ? _c(
                    "vue-custom-scrollbar",
                    {
                      staticClass: "popup-ui__scrollbar custom-scrollbar-ui",
                      attrs: { settings: _vm.settingsScrollbar }
                    },
                    [
                      _vm.$slots.header
                        ? _c(
                            "div",
                            { staticClass: "popup-ui__content-header" },
                            [_vm._t("header")],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "popup-ui__content-main",
                          class: {
                            "popup-ui__content-main--with-header":
                              _vm.$slots.header
                          }
                        },
                        [_vm._t("default")],
                        2
                      )
                    ]
                  )
                : [
                    _vm.$slots.header
                      ? _c(
                          "div",
                          { staticClass: "popup-ui__content-header" },
                          [_vm._t("header")],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "popup-ui__content-main",
                        class: {
                          "popup-ui__content-main--with-header":
                            _vm.$slots.header
                        }
                      },
                      [_vm._t("default")],
                      2
                    )
                  ]
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }