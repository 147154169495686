<template>
  <div
    ref="popupUI"
    class="popup-ui"
    :class="{
      'inner-scroll': innerScroll,
      'inner-scroll-mobile': innerScrollMobile,
      'no-inner-indent': noInnerIndent,
      'no-animation': noAnimation
    }"
  >
    <div
      class="popup-ui__backdrop"
      :class="{'hide-pointer': hidePointerCursorOnBackdrop || preventCloseEvent}"
      @click="onClose"
    />
    <div
      class="popup-ui__inner"
      :class="{ 'small': small, 'bg-dark': $slots.header }"
      :style="externalCss"
    >
      <div
        v-if="!hideCloseButton && !preventCloseEvent"
        class="popup-ui__close"
        @click="onClose"
      >
        <Icon
          icon="r-close-window-rounded"
          class="popup-ui__close-icon"
        />
      </div>
      <div
        ref="basePopupInner"
        class="popup-ui__content"
      >
        <vue-custom-scrollbar
          v-if="innerScroll || (innerScrollMobile && typeDevice === 'mobile')"
          :settings="settingsScrollbar"
          class="popup-ui__scrollbar custom-scrollbar-ui"
        >
          <div
            v-if="$slots.header"
            class="popup-ui__content-header"
          >
            <slot name="header" />
          </div>
          <div
            class="popup-ui__content-main"
            :class="{ 'popup-ui__content-main--with-header': $slots.header }"
          >
            <slot />
          </div>
        </vue-custom-scrollbar>
        <template v-else>
          <div
            v-if="$slots.header"
            class="popup-ui__content-header"
          >
            <slot name="header" />
          </div>
          <div
            class="popup-ui__content-main"
            :class="{ 'popup-ui__content-main--with-header': $slots.header }"
          >
            <slot />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { getTypeDevice } from '../../../utils/common';
import Icon from './Icon.vue';
import '../../../../../svg-icons/r-close-window-rounded.svg';

export default {
  name       : 'PopupUI',
  components : {
    vueCustomScrollbar,
    Icon
  },
  props: {
    small: {
      type    : Boolean,
      default : false
    },
    hideCloseButton: {
      type    : Boolean,
      default : false
    },
    hidePointerCursorOnBackdrop: {
      type    : Boolean,
      default : false
    },
    innerScroll: {
      type    : Boolean,
      default : false
    },
    innerScrollMobile: {
      type    : Boolean,
      default : false
    },
    maxWidth: {
      type    : Number,
      default : null
    },
    disableBodyScroll: {
      type    : Boolean,
      default : true
    },
    customDisableBodyScroll: {
      type    : Boolean,
      default : false
    },
    scrollY: {
      type    : Number,
      default : 0
    },
    noInnerIndent: {
      type    : Boolean,
      default : false
    },
    preventCloseEvent: {
      type    : Boolean,
      default : false
    },
    noAnimation: {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
      typeDevice        : '',
      oldTypeDevice     : '',
      settingsScrollbar : {
        wheelPropagation    : false,
        scrollYMarginOffset : 5
      }
    };
  },
  computed: {
    externalCss() {
      const result = [];

      if (this.maxWidth) {
        result.push(`max-width:${this.maxWidth}px;`);
      }

      return result.join('');
    }
  },
  mounted() {
    // Определяем тип устройства
    this.setTypeDevice();
    window.addEventListener('resize', this.setTypeDevice);

    if (this.customDisableBodyScroll) {
      const el = document.querySelector('#page_root');
      const notFf = navigator.userAgent.indexOf('Firefox') === -1;
      if (el && notFf) {
        document.body.style.overflowY = 'scroll';
        el.style.position = 'fixed';
        el.style.top = `-${this.scrollY}px`;
        el.style.width = '100%';
      }
    }

    if (this.disableBodyScroll) {
      disableBodyScroll(this.$el);
    }
  },

  beforeDestroy() {
    if (this.customDisableBodyScroll) {
      const el = document.querySelector('#page_root');
      const notFf = navigator.userAgent.indexOf('Firefox') === -1;
      if (el && notFf) {
        const scrollY = el.style.top;
        document.body.style.overflowY = '';
        el.style.position = '';
        el.style.top = '';
        el.style.width = '';

        // eslint-disable-next-line radix
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }

    if (this.disableBodyScroll) {
      enableBodyScroll(this.$el);
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.setTypeDevice);
  },

  methods: {
    onClose($event) {
      if (this.preventCloseEvent) {
        return;
      }

      $event.preventDefault();
      $event.stopPropagation();

      this.$emit('on-close');
    },

    setTypeDevice() {
      this.oldTypeDevice = this.typeDevice;
      this.typeDevice = getTypeDevice();
    },

    setScroll(value) {
      this.$refs.popupUI.scrollTop = value;
    }
  }
};
</script>

<style lang="scss">
@import "../../../../../css/redesign/components/common/popup-ui";
</style>
